.deleteSection{
  background: #34495e;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 9;
}

.image-block__wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  &__image{
    position: relative;
  }
  input{
    margin: 15px 0;
    padding: 10px 25px;
    background-color: #636e72;
    color: #fff;
    border-radius: 5px;
  }
  img{
    max-width: 100%;
    max-height: 500px;
  }
}

.tox-notifications-container{
  display: none!important;
}
.htmlBuilder{
  body{
    margin-top: 0px!important;
  }
  .tox-edit-area{
    iframe{
      padding: 15px!important;
      body{
        margin-top: 0px!important;
      }
    }
  }
  .reverse-row{
    flex-direction: row-reverse;
  }

  .tinymce-svg-editor{
    .tox-editor-header{
      display: none;
    }
  }
}

.tinymce-previev{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  width: 100%;
  max-width: 845px;
  .tox-editor-header{
    display: none;
  }
}

.shop-list__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  input{
    width: 100%;
    margin: 10px 0;
    padding: 6px 10px;
  }
  button{
    white-space: nowrap;
  }
}

.dk-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.mb-10{
  margin-bottom: 10px;
}

.complete_urls{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #000;
  margin: 15px auto 0 auto;
  transform: translateX(-30px);
  &.complete{
    background: #0acf97;
  }
  &.uncomplete{
    background: #fa5c7c;
  }
}

.statistic__table{
  td{
    text-align: center!important;
    padding: 10px 0px!important;
    vertical-align: middle;
  }
  th{
    padding: 10px 0px!important;
    font-size: 12px;
    transition: .4s ease;
    text-align: center!important;
    width: 30px;
  }
}

.linkList{
  li{
    font-size: 16px;
    &.lower{
      color: #0acf97;
      a{
        color: #0acf97;
      }
    }
    &.higher{
      color: #fa5c7c;
      a{
        color: #fa5c7c;
      }
    }
    &.same{
      color: #afafaf;
      a{
        color: #afafaf;
      }
    }
  }
}

.spinner--fixed{
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: #0000007a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2{
    color: #fff;
    margin-top: 20px;
  }
}

.youtubeContainer,
.dkcode__youtube {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.table .thead-custom-dark th {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.form-search-fit {
  width: fit-content;
  display: inline-block;
}

.action-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
}

.email-content {
  p {
    margin-bottom: .3rem;
  }

  blockquote {
    border-left: 2px solid #999;
    padding-left: 8px;
    margin: 0;
  }
}

.inline-input {
  display: inline-block;
  width: unset;
  height: unset;
  border-color: transparent;
  padding: 0px 6px;

  &:focus {
    border-color: #c1c9d0;
  }

  &--to-save {
    border: 1px solid #0acf97;
  }

  &--datepicker {
    display: inline-block;
    
    .react-datepicker {
      &-wrapper {
        display: inline-block;
      }
      &__input-container {
        display: inline-block;
      }
    }
  }
}

textarea.inline-input {
  width: 100%;
}

.btn {
  &-baselinker {
    background-color: #4283ef;
    color: #fff;
    box-shadow: 0px 2px 6px 0px rgba(108, 117, 125, 0.5);

    &:hover {
      background-color: #2c67c6;
      color: #fff;
    }
  }
}

.bg {
  &-baselinker {
    background-color: #4283ef;
  }
}

.spinner {
  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
}

@keyframes tableRedHighlight {
  0% {
    background-color: rgba($color: #fa5c7c, $alpha: .2);
  } 
  20% {
    background-color: rgba($color: #fa5c7c, $alpha: .4);
  }
  100% {
    background-color: rgba($color: #fa5c7c, $alpha: .2);
  } 
}

.react-photo-gallery {
  &--gallery {
    > div {
      padding: 10px 0;
      gap: 10px;
    }
  }

  &--photo {
    position: relative;
    background-color: #fff;

    span {
      position: absolute;
      top: -3px;
      left: -3px;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fa5c7c;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    img {
      padding: 10px;
      object-fit: contain;
      border: 1px solid #dddddd;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.btn-open-ai {
  color: #fff;
  background-color: rgb(25,195,125);

  &:hover {
    color: #fff;
    background-color: rgb(15, 172, 106);
  }

  svg {
    height: 18px;
    width: auto;
    margin-right: 5px;

    path {
      fill: #fff;
    }
  }
}

.bg-open-ai {
  background-color: rgb(15, 172, 106);
}

.product-preview {
  .section-edited {
    border-left: 3px solid #2c8ef8;

    .card-body {
      padding-left: calc(1.5rem - 3px);
    }
  }
  
  .zip-content {
    max-width: 860px;
    padding: 20px;
    border: 1px solid gray;
    border-radius: 5px;
  }
}

.tox-notifications-container {
  opacity: 0 !important;
  visibility: hidden !important;
}