/* Animated input type checkbox */
.dkcode {
  &--products-generator {
    input[type='checkbox'] {
      display: none;

      &:checked {
        + label {
          &::after {
            opacity: 1;
            transform: rotate(45deg) scale(1);
          }

          &::before {
            background-color: #fa5c7c;
            border-color: transparent;
          }
        }
      }
    }
    label {
      position: relative;
      cursor: pointer;

      &::after {
        content: '' !important;
        position: absolute;
        display: block;
        top: 6px;
        left: 5px;
        width: 5px;
        height: 9px;
        opacity: 0;
        margin: 0 !important;
        transform: rotate(45deg) scale(0);
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transition: all 0.3s ease;
      }

      &.listing {
        &::after {
          top: 4px;
        }
      }

      &::before {
        position: relative;
        display: inline-block;
        top: 2px;
        width: 15px;
        height: 15px;
        border: 1px solid #c8ccd4;
        border-radius: 3px;
        margin-right: 6px;
        background-color: #fff;
        transition: background-color 0.1s ease;
        content: '';
        cursor: pointer;
      }
    }

    textarea {
      border: 1px solid rgb(225, 225, 225);

      &:focus-visible {
        border: 1px solid rgb(225, 225, 225);
      }
    }

    &.spinner-counter {
      margin-top: 12px;
      padding: 2px 14px;
      font-size: 18px;
      font-weight: 400;
      background-color: #727cf5;
      color: #fff;
      border-radius: 30px;
    }
  }
  &--products-generator-category {
    input[type='checkbox'] {
      display: none;

      &:checked {
        + span {
          &::after {
            opacity: 1;
            transform: rotate(45deg) scale(1);
          }

          &::before {
            background-color: #fa5c7c;
            border-color: transparent;
          }
        }
      }
    }
    span {
      position: relative;
      cursor: pointer;

      &::after {
        content: '' !important;
        position: absolute;
        display: block;
        top: 5px;
        left: 5px;
        width: 5px;
        height: 9px;
        opacity: 0;
        margin: 0 !important;
        transform: rotate(45deg) scale(0);
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transition: all 0.3s ease;
      }

      &.listing {
        &::after {
          top: 4px;
        }
      }

      &::before {
        position: relative;
        display: inline-block;
        top: 2px;
        width: 15px;
        height: 15px;
        border: 1px solid #c8ccd4;
        border-radius: 3px;
        margin-right: 6px;
        background-color: #fff;
        transition: background-color 0.1s ease;
        content: '';
        cursor: pointer;
      }
    }

    textarea {
      border: 1px solid rgb(225, 225, 225);

      &:focus-visible {
        border: 1px solid rgb(225, 225, 225);
      }
    }

    &.spinner-counter {
      margin-top: 12px;
      padding: 2px 14px;
      font-size: 18px;
      font-weight: 400;
      background-color: #727cf5;
      color: #fff;
      border-radius: 30px;
    }
  }


} 

.infinite-scroll-component {
  padding: 5px 25px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  border: none !important;
}

.rdw-colorpicker-modal {
  width: 230px !important;
  height: fit-content !important;
}