.mt-20{
  margin-top: 20px
}
.mr-10{
  margin-right: 10px
}
.mb-10{
  margin-bottom: 10px
}
.mb-15{
  margin-bottom: 15px
}
.modal-body{
  .custom-radio{
    margin-bottom:10px
  }
}
.productButton{
  margin-right: 10px
}
.radioProductCategory{
  margin-top: 20px;
  margin-bottom: 20px;
}
.otherCategory{
  .custom-radio{
    margin-bottom: 10px;
  }
}
.items--grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  margin-top: 30px;
  .activeTile{
    border: 2px solid #badc58!important;
  }
  .single--item--wrapper{
    display: grid;
    grid-template-columns: 110px 1fr;
    align-items: center;
    border: 2px solid #222;
    padding: 10px;
    transition: .2s;
    cursor: pointer;
    &:hover{
      background: #ebebeb;
    }
    .item__content--desc{
      h2{
        font-size: 15px;
        color: #222;
        margin-bottom: 10px;
      }
      .single--item{
        p{
          margin-bottom: 1px;
          font-size: 14px;
        }
      }
    }
  }

  img{
    max-height: 110px;
    max-width: 110px;
  }
}

.powiadomienie,.powiadomienie-error,.powiadomienie-edit,.powiadomienie-edit-error{
  position: fixed;
  top: 100px;
  right: 25px;
  display: none;
  z-index: 999;
}

#js-addProductAllegro{
  display: none;
}

.allegro__btn {
  &-badge {
    &::after {
      content: '';
      position: absolute;
      background-color: #FFD557;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      top: -4px;
      margin-left: 4px;
      box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.7);
    }
  }
}

.allegro-edit-close-img {
  &::after {
    content: 'X';
    position: absolute;
    background-color: gray;
    right: -4px;
    top: -9px;
    color: #fff;
    font-size: 10px;
    padding: 3px 7px;
    border-radius: 100%;
    cursor: pointer;
  }
}

.allegro-edit-close-desc-img {
  &::after {
    content: 'X';
    position: relative;
    background-color: gray;
    left: 36%;
    top: 8px;
    color: #fff;
    font-size: 10px;
    padding: 3px 7px;
    border-radius: 100%;
    cursor: pointer;
  }
}

.ab-modal {
  & img {
    width: 100%;
    height: auto;
  }
}