// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {
    th, td {
      white-space: nowrap;
    }
}

.action-icon{
	&.products-page{
		i{
			position: relative;
			top: 3px;
		}
	}
} 

.extens,.extens-brand{
	display: none;
}
.react-bootstrap-table{
	tr{
		.reset-expansion-style{
			background-color: #e2e2e2;
			padding-left: 10px!important;
			padding-top:0px!important;
			padding-bottom:0px!important;
			.row-expansion-style{
				padding: 0px;
			}
			& > div{
				transition: none!important;
			}
		}
		.brand__wrapper{
			display: grid;
			grid-template-columns: repeat(30, 1fr);
			grid-gap: 25px;
			.brand__box{
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 8px 0;
				& > span{
					text-transform: uppercase;
					margin-bottom: 8px;
				}
				span{
					&.better_price{
						color: #b71d25;
						font-weight: bold;
					}
				}
			}
		}
		td,th{
			text-align: center;
		}
	}
	&.table-responsive--allegro{
		th.checkbox {
			width: 50px;
		}
		th.actions {
			width: 90px;
		}
		th.idMagento {
			width: 100px;
		}
		td ul {
			padding-left: 20px;
			margin-bottom: 0;
		}
		@media all and (max-width: 1499px){
			th.delivery {
				width: 150px;
			}
		}
		@media all and (min-width: 1500px){
			th.delivery {
				width: 250px;
			}
			th.params {
				width: 180px;
			}
		}
	}
	@media all and (min-width: 768px){
		.desktop--hide{
			display: none!important;
		}
	}
	@media all and (max-width: 767px){
		overflow-x: hidden;
		overflow-y: scroll;
		&.table-responsive--allegro{
			.table{
				.thead-light{
					th{
						width: 50%!important;
					}
					.sortable{
						input{
							display: none;
						}
					}
				}	
			}
		}
		table{
			table-layout: fixed!important;
		}
		.mobile--hide{
			display: none;
		}
		.thead-light{
			th{
				&:nth-child(3){
					width: 50%
				}
				&:nth-last-child(1){
					width: 30%
				}
				width: 20%;
			}
		}
		.brand__wrapper{
			grid-template-columns: repeat(1, 1fr)!important;
			grid-gap: 0px!important;
			.brand__box{
				flex-direction: row!important;
				justify-content: space-between;
				border-bottom: 1px solid #ccc;
				margin-bottom: 10px;
				padding-bottom: 10px;
				& > span{
					margin-bottom: 0px!important;
				}
			}
		}
	}
}

.loader__wrapper{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: rgba(255, 255, 255, 0.479);
}
.table-responsive{
	.table-action{
		display: flex;
	}
	&--allegro{
		@media all and (min-width: 768px){
			.reset-expansion-style{
				display: none;
			}
		}
	}
	&.table-users{
		td,th{
			text-align: left;
		}
	}
	th,td{
		text-align: left!important;
		padding: 0.65rem!important;
	}
}

.allegroImageSize{
	max-height: 60px;
}
.flex--box{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	p{
		margin-bottom: 7px;
	}
	.flex{
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			&.strong{
				margin: 0 3px;
				font-weight: bold;
			}
		}
		&.active{
			color: rgb(1, 148, 1);
		}
		&.ended,
		&.inactive{
			color: #B71D25;
		}
	}
	&.allegoAuctionNameWrapper{
		p{
			margin-bottom: 2px;
			&.allegroName{
				font-weight: bold;
				margin-bottom: 13px;
				font-size: 15px;
			}
			&.allegroSKU{
				font-style: italic;
			}
			&.allegroSignature{
				font-size: 12px;
			}
		}
	}
}

.maxSearchWidth{
	max-width: 110px;
}
.reverseSearchInput{
	position: relative;
	height: 95px;
	input,select{
		position: absolute;
		top: 10px;
		left: 13px;
		width: 80%;
		padding: 0 0 0 10px;
	}
}
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	}
	
	.dropup, .dropdown {
	position: relative;
	}
	
	.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid \9;
	content: "";
	}

	.hiddenCol{
		display: none;
	}


	.DraftEditor-editorContainer{
		border: 1px solid #F1F1F1;
		border-left: 1px solid #F1F1F1!important;
		padding: 0px 12px;
		min-height: 200px
	}

	.singleAttachment{
		width: 100%;
		p{
			border: 1px solid #dee2e6;
			padding: 15px 10px;
		}
	}
	.attachment--button{
		margin-bottom: 15px;
	}
	.attachment--select{
		margin-bottom: 15px
	}

	.attachmentDelete{
		cursor: pointer;
		font-weight: bold;
	}

	.row{
		.form-group{
			width: 100%;
		}
	}

	.ml-10{
		margin-left: 10px;
	}
	.mt-10{
		margin-top: 10px;
	}
	.ml-20{
		margin-left: 20px;
	}


	// custom modal

	// .modal--custom{
	// 	position: fixed;
  //   transform: translate(-50%, -50%);
  //   width: 100%;
  //   height: 100%;
  //   top: 50%;
  //   left: 50%;
  //   background: hsla(214, 18%, 23%, 0.7);
  //   padding: 50px 0px;
	// 	z-index: 9999999999;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
	// 	// display: none;
	// 	&__wrapper{
	// 		max-width: 70%;
	// 		background: #fff;
	// 		padding: 20px 40px;
	// 		overflow-y: scroll;
	// 		height: 90%;
	// 		border-radius: 20px;
	// 	}
	// }