//
// text.scss
// 

// Weight

.font-weight-semibold  { font-weight: $font-weight-semibold !important; }

.cursor-pointer {
    cursor: pointer;
}